import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { graphql, Script } from "gatsby";
import Layout from "../components/Layout/Layout";
import "../styles/base.scss";
import Modules from "../components/Modules/Modules";
// import { Helmet } from "react-helmet";
import TwoColumnStatic from "../components/TwoColumnStatic/TwoColumnStatic";
// import ContentBlock from "../components/ContentBlock/ContentBlock";
import ColumnStatic from "../components/TwoColumns/Columns/ColumnStatic/ColumnStatic";
const careers = ({ data, location }) => {
    const { page } = data;
    const modulesData = page.pageContent.modules;
    const href = location.href;
    // const [loaded, setLoaded] = useState(0);
    // const [frameHeight, setFrameHeight] = useState("100%");
    // const frameRef = useRef(null);
    // console.log({ frameHeight });

    // console.log(modulesData);

    const {
        description: seoDescription,
        keywords: seoKeywords,
        noindex: seoNoIndex,
        title: seoTitle,
        data: seoData,
        altCanonical,
    } = page.pageContent.seo;

    const seoPageData = {
        title: seoTitle ? seoTitle : page.title,
        description: seoDescription ? seoDescription : "",
        keywords: seoKeywords ? seoKeywords : null,
        noIndex: seoNoIndex ? seoNoIndex : false,
        data: seoData ? seoData : false,
        altCanonical: altCanonical,
        href: href,
    };

    const rightSpotifyContent = (
        <div className="two-column-static__iframe">
            <iframe
                src="https://open.spotify.com/embed/playlist/33qmKjpcTjCgjtqUtcasGs?si=nYSDRGufQ2a_eG4s-Tz5HA"
                style={{ width: "100%" }}
                height="380"
                frameBorder="0"
                allowtransparency="true"
            ></iframe>
        </div>
    );

    const leftSpotifyContent = (
        <ColumnStatic
            innerText={[
                <span className="text-grey text-xl">
                    At 7shifts, our people are as unique as their music tastes. Here is what the
                    team has been listening to.
                </span>,
            ]}
            headingText={["Kick out the ", <span className="accent">jams</span>, "!"]}
        />
    );

    // function resizeIFrameToFitContent(iFrame) {
    //     // iFrame.width = iFrame.contentWindow.document.body.scrollWidth;
    //     // iFrame.height = iFrame.contentWindow.document.body.scrollHeight;
    //     setFrameHeight(iFrame.contentWindow.document.body.scrollHeight);
    //     console.log(iFrame.contentWindow.document.body.scrollHeight);
    // }

    // window.addEventListener("DOMContentLoaded", function (e) {
    //     var iFrame = document.getElementById("careers-iframe");
    //     resizeIFrameToFitContent(iFrame);

    //     // or, to resize all iframes:
    //     // var iframes = document.querySelectorAll("iframe");
    //     // for (var i = 0; i < iframes.length; i++) {
    //     //     resizeIFrameToFitContent(iframes[i]);
    //     // }
    // });

    // useEffect(() => {
    //     if (loaded === 1) {
    //         document.body.append(`<script
    //         type="text/javascript"
    //         src="https://boards.greenhouse.io/embed/job_board/js?for=7shifts"
    //     ></script>`);
    //     }
    // }, []);

    return (
        <Layout
            seoData={seoPageData}
            heroContent={page.pageContent.hero}
            pageHeading={page.pageContent.pageHeading}
            hideForm
        >
            {/* <Helmet htmlAttributes={{ lang: "en" }} defer={true}>
                    <script
                        type="text/javascript"
                        async
                        src="https://boards.greenhouse.io/embed/job_board/js?for=7shifts"
                    ></script>
                </Helmet> */}
            {page.pageContent && page.pageContent.modules && <Modules modulesData={modulesData} />}
            {/* <div id="career-postings" style={{ width: "100%" }}> */}
            <div id="grnhse_app"></div>
            {/* {loaded && (
                <script
                    type="text/javascript"
                    src="https://boards.greenhouse.io/embed/job_board/js?for=7shifts"
                ></script>
            )} */}
            {/* </div> */}
            {/*
                <Script
                    id="greenhouse-script"
                    src={"https://boards.greenhouse.io/embed/job_board/js?for=7shifts"}
                /> */}
            {/* <iframe
                    id="careers-iframe"
                    src="https://boards.greenhouse.io/7shifts"
                    style={{ width: "100%" }}
                    height={frameHeight}
                    frameBorder="0"
                    allowtransparency="true"
                ></iframe> */}

            <TwoColumnStatic leftContent={leftSpotifyContent} rightContent={rightSpotifyContent} />
        </Layout>
    );
};

export default careers;

export const query = graphql`
    query careerPage {
        page: sanityPage(pageTitle: { eq: "Careers" }) {
            title: pageTitle
            slug {
                current
            }
            id
            pageContent {
                seo {
                    description
                    title
                    keywords
                    noindex
                    data
                }
                hero: heroBanner {
                    eyebrow
                    title: heroTitle {
                        style
                        _key
                        _type
                        children {
                            _type
                            _key
                            marks
                            text
                        }
                    }
                    marqueeText
                    marqueeImages {
                        ...ImageWithPreview
                        asset {
                            metadata {
                                dimensions {
                                    width
                                    height
                                }
                            }
                            url
                            filename
                        }
                    }
                    primaryImage {
                        ...ImageWithPreview
                    }
                    primaryalt
                    secondaryImage {
                        ...ImageWithPreview
                    }
                    secondaryalt
                    backdropImage {
                        ...ImageWithPreview
                    }
                    ctaButtonOne {
                        linkTo {
                            external
                            internal {
                                slug {
                                    current
                                }
                            }
                        }
                        title
                    }
                    ctaButtonTwo {
                        title
                        linkTo {
                            external
                            internal {
                                slug {
                                    current
                                }
                            }
                        }
                    }
                    subtext
                }
                modules {
                    ... on SanityHomeSteps {
                        _key
                        _type
                        title: stepsTitle {
                            style
                            _key
                            _type
                            children {
                                _type
                                _key
                                marks
                                text
                            }
                        }
                        stepsBulletTitle {
                            style
                            _key
                            _type
                            children {
                                _type
                                _key
                                marks
                                text
                            }
                        }
                        imageSizing
                        stepsList {
                            title
                            text
                            image {
                                ...ImageWithPreview
                            }
                            alt
                            icon {
                                ...ImageWithPreview
                            }
                            backdropImage {
                                ...ImageWithPreview
                            }
                            ctaText
                            ctaLink {
                                external
                                internal {
                                    slug {
                                        current
                                    }
                                }
                            }
                        }
                    }
                    ... on SanityTestimonials {
                        _key
                        _type
                        testimonialList {
                            jobTitle
                            name
                            quote
                            logoAlt
                            image {
                                ...ImageWithPreview
                            }
                            logo {
                                ...ImageWithPreview
                            }
                        }
                    }
                    ... on SanityStats {
                        backdropImage {
                            ...ImageWithPreview
                        }
                        _key
                        _type
                        title {
                            style
                            list
                            _key
                            _type
                            children {
                                _type
                                _key
                                marks
                                text
                            }
                        }
                        statOne {
                            text
                            number
                            icon {
                                ...ImageWithPreview
                            }
                        }
                        statThree {
                            text
                            number
                            icon {
                                ...ImageWithPreview
                            }
                        }
                        statTwo {
                            text
                            number
                            icon {
                                ...ImageWithPreview
                            }
                        }
                    }
                    ... on SanityAccordian {
                        _key
                        _type
                        title: accordianTitle {
                            style
                            _key
                            _type
                            children {
                                _type
                                _key
                                marks
                                text
                            }
                        }
                        list {
                            title
                            description {
                                style
                                _key
                                _type
                                children {
                                    _type
                                    _key
                                    text
                                }
                            }
                        }
                    }
                    ... on SanityFullTextBlock {
                        _key
                        _type
                        heading {
                            style
                            _key
                            _type
                            children {
                                _type
                                _key
                                marks
                                text
                            }
                        }
                        body {
                            style
                            _key
                            _type
                            children {
                                _type
                                _key
                                marks
                                text
                            }
                        }
                        internalLink
                        ctaText
                        ctaLink
                    }
                    ... on SanitySocialProofMarquee {
                        _key
                        _type
                        title: headingText {
                            style
                            _key
                            _type
                            children {
                                _type
                                _key
                                marks
                                text
                            }
                        }
                        useCustomLogos
                        useCustomHeading
                        socialProofImages {
                            ...ImageWithPreview
                            asset {
                                metadata {
                                    dimensions {
                                        width
                                        height
                                    }
                                }
                                url
                                filename
                            }
                        }
                    }
                    ... on SanityCardGroup {
                        _key
                        _type
                        title: cardGroupTitle {
                            style
                            _key
                            _type
                            children {
                                _type
                                _key
                                marks
                                text
                            }
                        }
                        extraWide
                        cardList {
                            body {
                                style
                                _key
                                _type
                                children {
                                    _type
                                    _key
                                    marks
                                    text
                                }
                            }
                            modalBody {
                                style
                                _key
                                _type
                                children {
                                    _type
                                    _key
                                    marks
                                    text
                                }
                            }
                            ctaLink
                            ctaText
                            ctaOpensModal
                            internalLink
                            heading {
                                style
                                _key
                                _type
                                children {
                                    _type
                                    _key
                                    marks
                                    text
                                }
                            }
                            iconAlt
                            icon {
                                asset {
                                    _id
                                    _key
                                    url
                                    filename
                                    metadata {
                                        dimensions {
                                            aspectRatio
                                            height
                                            width
                                        }
                                    }
                                }
                            }
                            _type
                            showBoxShadow
                        }
                    }
                    ... on SanityDemoForm {
                        _key
                        _type
                        showDemoForm
                    }
                    ... on SanityTwoColumns {
                        _key
                        _type
                        swappable {
                            _type
                            _key
                            title: titleWithAccent {
                                style
                                _key
                                _type
                                children {
                                    _type
                                    _key
                                    marks
                                    text
                                }
                            }
                            columnOne {
                                columnTypes {
                                    ... on SanityColumnDetails {
                                        _key
                                        _type
                                        simpleLink {
                                            external
                                            internal {
                                                slug {
                                                    current
                                                }
                                            }
                                            title
                                        }
                                        text
                                        title: titleWithAccent {
                                            style
                                            _key
                                            _type
                                            children {
                                                _type
                                                _key
                                                marks
                                                text
                                            }
                                        }
                                    }
                                    ... on SanityColumnImages {
                                        _key
                                        _type
                                        image {
                                            ...ImageWithPreview
                                        }
                                        alt
                                        marginTop

                                        backdropImage {
                                            ...ImageWithPreview
                                        }
                                        larger
                                        popups {
                                            headline
                                            text
                                        }
                                    }
                                    ... on SanitySubLinks {
                                        _key
                                        _type
                                        subLinksList {
                                            icon {
                                                ...ImageWithPreview
                                            }
                                            simpleLink {
                                                external
                                                title
                                                internal {
                                                    slug {
                                                        current
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    ... on SanitySubColumns {
                                        _key
                                        _type
                                        subColumnsList {
                                            title
                                            text
                                            image {
                                                ...ImageWithPreview
                                            }
                                        }
                                    }
                                }
                            }
                            columnTwo {
                                columnTypes {
                                    ... on SanityColumnDetails {
                                        _key
                                        _type
                                        simpleLink {
                                            external
                                            internal {
                                                slug {
                                                    current
                                                }
                                            }
                                            title
                                        }
                                        text
                                        title: titleWithAccent {
                                            style
                                            _key
                                            _type
                                            children {
                                                _type
                                                _key
                                                marks
                                                text
                                            }
                                        }
                                    }
                                    ... on SanityColumnImages {
                                        _key
                                        _type
                                        image {
                                            ...ImageWithPreview
                                        }
                                        alt
                                        marginTop
                                        backdropImage {
                                            ...ImageWithPreview
                                        }
                                        larger
                                        popups {
                                            headline
                                            text
                                        }
                                    }
                                    ... on SanitySubLinks {
                                        _key
                                        _type
                                        subLinksList {
                                            icon {
                                                ...ImageWithPreview
                                            }
                                            simpleLink {
                                                external
                                                title
                                                internal {
                                                    slug {
                                                        current
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    ... on SanitySubColumns {
                                        _key
                                        _type
                                        subColumnsList {
                                            title
                                            text
                                            image {
                                                ...ImageWithPreview
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    ... on SanityThreeColumns {
                        _key
                        _type
                        threeColumnOne {
                            text
                            title
                            image {
                                ...ImageWithPreview
                            }
                            backdropImage {
                                ...ImageWithPreview
                            }
                        }
                        threeColumnTwo {
                            text
                            title
                            image {
                                ...ImageWithPreview
                            }
                            backdropImage {
                                ...ImageWithPreview
                            }
                        }
                        threeColumnThree {
                            text
                            title
                            image {
                                ...ImageWithPreview
                            }
                            backdropImage {
                                ...ImageWithPreview
                            }
                        }
                    }
                }
                pageHeading {
                    style
                    _key
                    _type
                    children {
                        _type
                        _key
                        marks
                        text
                    }
                }
            }
        }
    }
`;
